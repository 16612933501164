export class Logger {
    info(message, ...args) {
        console.log(message, ...args);
    }
    warn(message, ...args) {
        console.warn(message, ...args);
    }
    error(message, ...args) {
        console.error(message, ...args);
    }
    debug(message, ...args) {
        console.debug(message, ...args);
    }
}
export default new Logger();
